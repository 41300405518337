exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bedrift-bredbandstjenester-kontakt-oss-index-js": () => import("./../../../src/pages/bedrift/bredbandstjenester/kontakt-oss/index.js" /* webpackChunkName: "component---src-pages-bedrift-bredbandstjenester-kontakt-oss-index-js" */),
  "component---src-pages-bedrift-bredbandstjenester-kontakt-oss-kvittering-js": () => import("./../../../src/pages/bedrift/bredbandstjenester/kontakt-oss/kvittering.js" /* webpackChunkName: "component---src-pages-bedrift-bredbandstjenester-kontakt-oss-kvittering-js" */),
  "component---src-pages-bedrift-naeringsservice-resultat-js": () => import("./../../../src/pages/bedrift/naeringsservice/resultat.js" /* webpackChunkName: "component---src-pages-bedrift-naeringsservice-resultat-js" */),
  "component---src-pages-blogg-js": () => import("./../../../src/pages/blogg.js" /* webpackChunkName: "component---src-pages-blogg-js" */),
  "component---src-pages-kontaktinformasjon-js": () => import("./../../../src/pages/kontaktinformasjon.js" /* webpackChunkName: "component---src-pages-kontaktinformasjon-js" */),
  "component---src-pages-kundeservice-chat-js": () => import("./../../../src/pages/kundeservice/chat.js" /* webpackChunkName: "component---src-pages-kundeservice-chat-js" */),
  "component---src-pages-kundeservice-driftsmeldinger-js": () => import("./../../../src/pages/kundeservice/driftsmeldinger.js" /* webpackChunkName: "component---src-pages-kundeservice-driftsmeldinger-js" */),
  "component---src-pages-kundeservice-strompriser-filters-js": () => import("./../../../src/pages/kundeservice/strompriser/Filters.js" /* webpackChunkName: "component---src-pages-kundeservice-strompriser-filters-js" */),
  "component---src-pages-kundeservice-strompriser-graph-js": () => import("./../../../src/pages/kundeservice/strompriser/Graph.js" /* webpackChunkName: "component---src-pages-kundeservice-strompriser-graph-js" */),
  "component---src-pages-kundeservice-strompriser-index-js": () => import("./../../../src/pages/kundeservice/strompriser/index.js" /* webpackChunkName: "component---src-pages-kundeservice-strompriser-index-js" */),
  "component---src-pages-kundeservice-strompriser-sign-up-form-js": () => import("./../../../src/pages/kundeservice/strompriser/SignUpForm.js" /* webpackChunkName: "component---src-pages-kundeservice-strompriser-sign-up-form-js" */),
  "component---src-pages-kundeservice-strompriser-statistics-js": () => import("./../../../src/pages/kundeservice/strompriser/Statistics.js" /* webpackChunkName: "component---src-pages-kundeservice-strompriser-statistics-js" */),
  "component---src-pages-kundeservice-tilbakemelding-index-js": () => import("./../../../src/pages/kundeservice/tilbakemelding/index.js" /* webpackChunkName: "component---src-pages-kundeservice-tilbakemelding-index-js" */),
  "component---src-pages-kundeservice-tilbakemelding-takk-js": () => import("./../../../src/pages/kundeservice/tilbakemelding/takk.js" /* webpackChunkName: "component---src-pages-kundeservice-tilbakemelding-takk-js" */),
  "component---src-pages-minelektriker-befaring-js": () => import("./../../../src/pages/minelektriker/befaring.js" /* webpackChunkName: "component---src-pages-minelektriker-befaring-js" */),
  "component---src-pages-minelektriker-ekom-js": () => import("./../../../src/pages/minelektriker/ekom.js" /* webpackChunkName: "component---src-pages-minelektriker-ekom-js" */),
  "component---src-pages-minelektriker-index-js": () => import("./../../../src/pages/minelektriker/index.js" /* webpackChunkName: "component---src-pages-minelektriker-index-js" */),
  "component---src-pages-om-nte-barekraft-i-nte-vanndata-js": () => import("./../../../src/pages/om-nte/barekraft-i-nte/vanndata.js" /* webpackChunkName: "component---src-pages-om-nte-barekraft-i-nte-vanndata-js" */),
  "component---src-pages-om-nte-karriere-bli-kjent-med-folkene-js": () => import("./../../../src/pages/om-nte/karriere/bli-kjent-med-folkene.js" /* webpackChunkName: "component---src-pages-om-nte-karriere-bli-kjent-med-folkene-js" */),
  "component---src-pages-om-nte-prosjekter-i-nte-js": () => import("./../../../src/pages/om-nte/prosjekter-i-nte.js" /* webpackChunkName: "component---src-pages-om-nte-prosjekter-i-nte-js" */),
  "component---src-pages-om-nte-sponsing-sjekk-status-js": () => import("./../../../src/pages/om-nte/sponsing/sjekk-status.js" /* webpackChunkName: "component---src-pages-om-nte-sponsing-sjekk-status-js" */),
  "component---src-pages-om-nte-sponsing-sok-om-midler-js": () => import("./../../../src/pages/om-nte/sponsing/sok-om-midler.js" /* webpackChunkName: "component---src-pages-om-nte-sponsing-sok-om-midler-js" */),
  "component---src-pages-produkter-bestill-js": () => import("./../../../src/pages/produkter/bestill.js" /* webpackChunkName: "component---src-pages-produkter-bestill-js" */),
  "component---src-pages-produkter-index-js": () => import("./../../../src/pages/produkter/index.js" /* webpackChunkName: "component---src-pages-produkter-index-js" */),
  "component---src-pages-redirect-app-js": () => import("./../../../src/pages/redirect/app.js" /* webpackChunkName: "component---src-pages-redirect-app-js" */),
  "component---src-pages-samtykke-portal-index-js": () => import("./../../../src/pages/samtykke-portal/index.js" /* webpackChunkName: "component---src-pages-samtykke-portal-index-js" */),
  "component---src-pages-samtykke-portal-kundeservice-js": () => import("./../../../src/pages/samtykke-portal/kundeservice.js" /* webpackChunkName: "component---src-pages-samtykke-portal-kundeservice-js" */),
  "component---src-pages-sok-js": () => import("./../../../src/pages/sok.js" /* webpackChunkName: "component---src-pages-sok-js" */),
  "component---src-pages-strom-js": () => import("./../../../src/pages/strom/[...].js" /* webpackChunkName: "component---src-pages-strom-js" */),
  "component---src-pages-strom-maalernummer-feil-js": () => import("./../../../src/pages/strom/maalernummer/feil.js" /* webpackChunkName: "component---src-pages-strom-maalernummer-feil-js" */),
  "component---src-pages-strom-maalernummer-kundeservice-js": () => import("./../../../src/pages/strom/maalernummer/kundeservice.js" /* webpackChunkName: "component---src-pages-strom-maalernummer-kundeservice-js" */),
  "component---src-pages-strom-maalernummer-registrer-js": () => import("./../../../src/pages/strom/maalernummer/registrer.js" /* webpackChunkName: "component---src-pages-strom-maalernummer-registrer-js" */),
  "component---src-pages-strom-maalernummer-suksess-js": () => import("./../../../src/pages/strom/maalernummer/suksess.js" /* webpackChunkName: "component---src-pages-strom-maalernummer-suksess-js" */),
  "component---src-pages-test-calendar-js": () => import("./../../../src/pages/test/calendar.js" /* webpackChunkName: "component---src-pages-test-calendar-js" */),
  "component---src-pages-test-form-js": () => import("./../../../src/pages/test/form.js" /* webpackChunkName: "component---src-pages-test-form-js" */),
  "component---src-pages-test-icon-js": () => import("./../../../src/pages/test/icon.js" /* webpackChunkName: "component---src-pages-test-icon-js" */),
  "component---src-pages-test-typeform-js": () => import("./../../../src/pages/test/typeform.js" /* webpackChunkName: "component---src-pages-test-typeform-js" */),
  "component---src-pages-tv-og-internett-bestill-en-siste-ting-js": () => import("./../../../src/pages/tv-og-internett/bestill/en-siste-ting.js" /* webpackChunkName: "component---src-pages-tv-og-internett-bestill-en-siste-ting-js" */),
  "component---src-pages-tv-og-internett-bestill-steg-1-js": () => import("./../../../src/pages/tv-og-internett/bestill/steg1.js" /* webpackChunkName: "component---src-pages-tv-og-internett-bestill-steg-1-js" */),
  "component---src-pages-tv-og-internett-bestill-steg-2-js": () => import("./../../../src/pages/tv-og-internett/bestill/steg2.js" /* webpackChunkName: "component---src-pages-tv-og-internett-bestill-steg-2-js" */),
  "component---src-pages-tv-og-internett-bestill-takk-for-din-henvendelse-js": () => import("./../../../src/pages/tv-og-internett/bestill/takk-for-din-henvendelse.js" /* webpackChunkName: "component---src-pages-tv-og-internett-bestill-takk-for-din-henvendelse-js" */),
  "component---src-pages-tv-og-internett-bestill-takk-js": () => import("./../../../src/pages/tv-og-internett/bestill/takk.js" /* webpackChunkName: "component---src-pages-tv-og-internett-bestill-takk-js" */),
  "component---src-pages-tv-og-internett-logg-inn-js": () => import("./../../../src/pages/tv-og-internett/logg-inn.js" /* webpackChunkName: "component---src-pages-tv-og-internett-logg-inn-js" */),
  "component---src-pages-tv-og-internett-utbyggingsstatus-js": () => import("./../../../src/pages/tv-og-internett/utbyggingsstatus.js" /* webpackChunkName: "component---src-pages-tv-og-internett-utbyggingsstatus-js" */),
  "component---src-templates-akademiet-akademiet-js": () => import("./../../../src/templates/akademiet/akademiet.js" /* webpackChunkName: "component---src-templates-akademiet-akademiet-js" */),
  "component---src-templates-blog-post-blog-post-js": () => import("./../../../src/templates/blog-post/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-blog-post-js" */),
  "component---src-templates-laget-mitt-index-js": () => import("./../../../src/templates/laget-mitt/Index.js" /* webpackChunkName: "component---src-templates-laget-mitt-index-js" */),
  "component---src-templates-laget-mitt-zone-js": () => import("./../../../src/templates/laget-mitt/Zone.js" /* webpackChunkName: "component---src-templates-laget-mitt-zone-js" */),
  "component---src-templates-page-page-js": () => import("./../../../src/templates/page/page.js" /* webpackChunkName: "component---src-templates-page-page-js" */),
  "component---src-templates-power-product-power-product-js": () => import("./../../../src/templates/power-product/PowerProduct.js" /* webpackChunkName: "component---src-templates-power-product-power-product-js" */),
  "component---src-templates-product-product-js": () => import("./../../../src/templates/product/product.js" /* webpackChunkName: "component---src-templates-product-product-js" */),
  "component---src-templates-redirect-redirect-js": () => import("./../../../src/templates/redirect/redirect.js" /* webpackChunkName: "component---src-templates-redirect-redirect-js" */),
  "component---src-templates-redirect-redirect-with-ninetailed-js": () => import("./../../../src/templates/redirect/RedirectWithNinetailed.js" /* webpackChunkName: "component---src-templates-redirect-redirect-with-ninetailed-js" */),
  "component---src-templates-success-story-success-story-js": () => import("./../../../src/templates/success-story/success-story.js" /* webpackChunkName: "component---src-templates-success-story-success-story-js" */)
}

