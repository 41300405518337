import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons/faAngleRight';

import Link, { AnchorLink } from 'components/Link';
import Heading from 'libs/heading';
import { AnimationWrap } from 'libs/animations';
import { getEmailLink } from 'libs/content';
import { getFontAwesomeIcon } from 'components/forms/Button';

//#region Styling
const Grid = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 15px;

	> a {
		flex: 1 1 calc(100% - 15px);
		${p =>
			p.theme.media.mediumOnly(css`
				flex: ${p =>
					(p.$perrow === 1 &&
						p.$perrow &&
						`1 1 calc(100% / ${p.$perrow} - 15px)`) ||
					'1 1 calc(100% / 2 - 15px)'};
			`)}
		${p =>
			p.theme.media.large(css`
				flex: ${p =>
					(p.$perrow && `1 1 calc(100% / ${p.$perrow} - 15px)`) ||
					'1 1 calc(100% / 3 - 15px)'};
			`)};
	}
`;

const SharedStyle = () => css`
	display: flex;
	align-items: flex-start;
	padding: 25px 35px;
	text-align: left;
	background: ${p => p.theme.colors.blue200};
	color: ${p => p.theme.colors.grey900};
	border-radius: ${p => p.theme.utils.borderRadius};
	transition: all 350ms ease;
	width: 100%;
	text-decoration: none;
	cursor: pointer;
	transition: all 350ms ease;
	position: relative;
	> div {
		width: 100%;
		pointer-events: none;
	}
	${p =>
		p.theme.media.smallOnly(css`
			padding: 15px;
		`)}
	&:hover {
		background: ${p => p.theme.colors.blue200};
		box-shadow: ${p =>
			`inset -2px 0 0 ${p.theme.colors.blue400}, inset 0 -2px 0 ${p.theme.colors.blue400}, inset 2px 0 0 ${p.theme.colors.blue400}, inset 0 2px 0 ${p.theme.colors.blue400}`};
	}
`;

const InternalLink = styled(Link)`
	${SharedStyle}
`;

const ExternalLink = styled(AnchorLink)`
	${SharedStyle}
`;
//#endregion

/**
 * Represents text elements a grid
 * @param {array} elements - The elements in the grid
 * @param {integer} perrow - The maximum number of elements per row (1-3)
 * @param {boolean} evenCount - Whether the total count of elements is an even number or not
 * @param {...object} componentSettings - The settings for the component
 * @param {string} headinglevel - The heading level for the title (default: h3)
 * @param {...object} props - Rest of the parameters for the component
 */
export default function Shortcuts({
	elements = [],
	files = [],
	perrow = 2,
	evenCount = false,
	componentSettings = [],
	headinglevel = 'h3',
	...props
}) {
	// combine elements and files
	const combinedElements = [...elements, ...files];

	if (!combinedElements?.length > 0) return;

	// Remove elements without link
	const elementsWithLinks = combinedElements.filter(
		element => element?.link || element?.email || element?.onClick
	);

	const totalCount = elementsWithLinks?.length;

	// Make sure not to allow more than three per row, and not to allow more than the total count
	perrow = totalCount < perrow ? totalCount : perrow > 3 ? 2 : perrow;

	return (
		<Grid $perrow={perrow} {...componentSettings}>
			{elementsWithLinks?.map((element, i) => {
				const LinkComponent =
					!element?.email && !!element?.internal
						? InternalLink
						: ExternalLink;

				return (
					<AnimationWrap
						key={i}
						perrow={perrow}
						evenCount={evenCount}
						doanimate={props?.transitions}
						delay={(i && 0.5 * i) || 0}
						direction="down">
						<LinkComponent
							title={element?.linkText || element?.title}
							target={element?.target}
							to={element?.internal ? element?.link : undefined}
							data-cy={element?.dataCy || 'shortcut'}
							href={
								!element?.internal && !!element?.email
									? getEmailLink(element?.email)
									: element?.link
									? element.link
									: undefined
							}
							onClick={e => {
								if (!element?.onClick) return;
								e.preventDefault();
								element?.onClick();
							}}>
							<TextAndIcon
								{...element}
								headinglevel={headinglevel}
							/>
						</LinkComponent>
					</AnimationWrap>
				);
			})}
		</Grid>
	);
}

const Inner = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	height: 100%;
`;

const Icon = styled.div`
	color: ${p => p.theme.colors.blue700};
	svg {
		width: 28px;
		height: auto;
		display: block;
		${p =>
			p.theme.media.smallOnly(css`
				width: 20px;
			`)}
	}
`;

const Title = styled(Heading)`
	color: ${p => p.theme.colors.blue700};
	margin: 0;
	font-size: 22px;
	line-height: 30px;
	${p =>
		p.theme.media.medium(css`
			font-size: 24px;
			line-height: 31px;
		`)}
`;
const Text = styled.p`
	margin: 0px;
	${p =>
		p.theme.media.smallOnly(css`
			font-size: 14px;
			line-height: 22px;
		`)}
	abbr {
		text-decoration: none;
	}
`;

const Arrow = styled.div`
	transition: all 350ms ease;
	align-self: center;
	margin-left: auto;
	svg {
		width: 14px;
		height: 22px;
		display: block;
		transition: all 350ms ease;
		color: ${p => p.theme.colors.blue700};
		${p =>
			p.theme.media.smallOnly(css`
				width: 12px;
				height: 18px;
			`)}
	}
`;

/**
 * Represents the title and the text
 * @param {string} title - The title
 * @param {string} text - The text
 * @param {string} icon - The icon
 * @param {string} headinglevel - The heading level for the title
 * @param {...object} props - The rest of the properties for the component
 */
function TextAndIcon({
	title = '',
	text = '',
	icon = '',
	headinglevel = 'h3',
	...props
}) {
	if (!title) return null;

	return (
		<Inner>
			{icon && (
				<Icon>
					{(props?.fontAwesome !== false && (
						<FontAwesomeIcon
							icon={getFontAwesomeIcon(icon)}
							size="lg"
							width="28"
							height="25"
						/>
					)) ||
						icon}
				</Icon>
			)}

			<div>
				<Title level={headinglevel} className="shortcut__title">
					{title}
				</Title>

				{text && (
					<Text>
						<span>{text}</span>
						{(props?.kb ||
							(!!props?.type && props?.type !== 'link')) && (
							<span>
								{' '}
								{props?.type && (
									<abbr title="Type">{props?.type}</abbr>
								)}
								{props?.kb && (
									<small>
										{' '}
										<abbr title="Kilobyte">
											({props?.kb})
										</abbr>
									</small>
								)}
							</span>
						)}
					</Text>
				)}
			</div>

			<Arrow>
				<FontAwesomeIcon
					icon={faAngleRight}
					size="lg"
					width="28"
					height="25"
				/>
			</Arrow>
		</Inner>
	);
}
