import React, {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { WebStorageStateStore } from 'oidc-client-ts';
import {
	AuthProvider as ReactOidcAuthProvider,
	useAuth as useReactOidcAuth,
} from 'react-oidc-context';

import { getUserId, track, useAnalytics } from 'context/AnalyticsProvider';
import { setupContext } from 'libs/analytics';
import { getUrlCategory } from 'libs/content';
import setupHeaders from 'libs/setup-headers';
import getOS from 'libs/platform';

const prefix = 'nte-auth-';
export const tokenKey = prefix + 'token';
export const userIdKey = prefix + 'user-id';
export const userLatestTicketKey = prefix + 'latest-ticket';
export const authMethods = {
	vipps: 'Vipps',
	bankId: 'BankID',
};

const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

export default function AuthProvider(props) {
	const [loading, setLoading] = useState(false);
	const [isLegal, setIsLegal] = useState(false);
	const [token, _setToken] = useState(null);
	const { identify } = useAnalytics();

	function setToken(value) {
		if (value) {
			sessionStorage.setItem(tokenKey, value);
			_setToken(value);
		} else {
			sessionStorage.removeItem(tokenKey);
			_setToken(null);
		}
	}

	const oidcConfig = useMemo(() => {
		if (!props?.isBrowser) return {};

		const authority =
			process.env.GATSBY_ENV_TESTING === 'true'
				? process.env.GATSBY_API_BASE_URL + 'test-oidc'
				: `${process.env.GATSBY_KEYCLOAK_URL}/realms/${process.env.GATSBY_KEYCLOAK_REALM}`;
		const client_id =
			process.env.GATSBY_ENV_TESTING === 'true'
				? 'testclient'
				: process.env.GATSBY_KEYCLOAK_CLIENTID;

		return {
			authority,
			client_id,
			client_secret: 'secret',
			redirect_uri: window.location.href,
			scope: 'openid nationalId providerId email profile phone',
			ui_locales: 'no-BM no en',
			stopCheckSessionOnError: false,
			automaticSilentRenew: true,
			onSigninCallback: user => {
				setLoading(true);

				setToken(user.access_token);

				const category = getUrlCategory(window?.location?.pathname);
				const context = setupContext();

				fetch(process.env.GATSBY_API_BASE_URL + 'status/get-id', {
					method: 'POST',
					headers: setupHeaders({
						'Content-Type': 'application/json',
					}),
					body: JSON.stringify({
						context,
						properties: {
							category,
							entry: getEntry(),
							currentUrl: window?.location?.href,
							Platform: 'nte.no',
						},
						method:
							user?.profile?.providerId === authMethods.vipps
								? authMethods.vipps
								: authMethods.bankId,
						os: getOS(),
						timestamp: new Date(),
					}),
				})
					.then(res => res.json())
					.then(json => {
						if (json?.trackingId) {
							localStorage.setItem(userIdKey, json.trackingId);

							// If user has tickets in Intercom, store the latest ticket id
							if (json?.intercomUser?.tickets?.length > 0) {
								console.log(
									'Storing latest ticket id:',
									json?.intercomUser?.tickets[0]?.ticket_id
								);
								localStorage.setItem(
									userLatestTicketKey,
									json?.intercomUser?.tickets[0]?.ticket_id
								);
							}

							identify({ userId: json.trackingId });

							track('User Signed In', {
								entry: getEntry(),
								method:
									user?.profile?.providerId ===
									authMethods.vipps
										? authMethods.vipps
										: authMethods.bankId,
								name: user?.profile?.name,
								email: user?.profile?.email,
								phone: user?.profile?.phone,
							});
						}
					})
					.catch(e => {
						console.error(e);
					})
					.finally(() => {
						if (user?.profile?.birthdate) {
							const age = getAge(user?.profile?.birthdate);
							setIsLegal(age > 17);
						}

						// remove query params after login
						const url = new URL(window.location.href);
						url.searchParams.delete('code');
						url.searchParams.delete('state');
						url.searchParams.delete('session_state');
						url.searchParams.delete('iss');
						window.history.replaceState({}, '', url);

						setLoading(false);
					});
			},
			userStore: new WebStorageStateStore({
				store: window.localStorage,
			}),
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props?.isBrowser]);

	return (
		<ReactOidcAuthProvider {...oidcConfig}>
			<AuthProviderContent
				isLegal={isLegal}
				loading={loading}
				token={token}
				setToken={setToken}
				{...props}
			/>
		</ReactOidcAuthProvider>
	);
}

function AuthProviderContent({ loading, isLegal, token, setToken, ...props }) {
	const auth = useReactOidcAuth();
	const [_method, setMethod] = useState(null);
	const isLoggingIn = useRef(false);
	const isLoggingOut = useRef(false);

	useEffect(() => {
		if (!auth?.isAuthenticated) return;

		auth.startSilentRenew();

		setToken(auth?.user?.access_token);

		if (auth?.user?.profile?.providerId?.includes(authMethods.vipps)) {
			setMethod(authMethods.vipps);
		} else {
			setMethod(authMethods.bankId);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth?.isAuthenticated]);

	function getIdpHint(method) {
		if (method?.toLowerCase() === authMethods.vipps.toLowerCase()) {
			return authMethods.vipps.toLowerCase();
		} else {
			return process.env.GATSBY_KEYCLOAK_IDPHINT_BANKID;
		}
	}

	/**
	 * @param {Object} options
	 * @param {string} options.redirect
	 * @param {string} options.method - vipps or bankid
	 **/
	function login({ redirect, method = authMethods.vipps } = {}) {
		if (auth.isAuthenticated || isLoggingIn?.current) return;

		isLoggingIn.current = true;

		setMethod(
			method === authMethods.vipps
				? authMethods.vipps
				: authMethods.bankId
		);

		let redirect_uri = redirect;
		if (!redirect_uri) {
			redirect_uri = window.location.href;
		} else if (redirect.includes('http')) {
			redirect_uri = redirect;
		} else {
			redirect_uri = window.location.origin + redirect;
		}

		auth.signinRedirect({
			login_hint:
				method === authMethods.vipps
					? authMethods.vipps
					: authMethods.bankId,
			redirect_uri,
			extraQueryParams: {
				kc_idp_hint: getIdpHint(method),
			},
		});

		isLoggingIn.current = false;
	}

	/**
	 * @param {Object} options
	 * @param {string} options.redirect
	 * @param {string} options.method - vipps or bankid
	 */
	async function logout({ redirect } = {}) {
		if (!auth.isAuthenticated || isLoggingOut?.current) return;

		isLoggingOut.current = true;

		fetch(process.env.GATSBY_API_BASE_URL + 'status/logout', {
			method: 'POST',
			headers: setupHeaders({
				'Content-Type': 'application/json',
			}),
			body: JSON.stringify({
				entry: getEntry(),
			}),
		})
			.catch(e => {
				console.error(e);
			})
			.finally(async () => {
				track('User Signed Out', {
					entry: getEntry(),
					method:
						auth?.user?.profile?.providerId === authMethods.vipps
							? authMethods.vipps
							: authMethods.bankId,
					name: auth?.user?.profile?.name,
					email: auth?.user?.profile?.email,
					phone: auth?.user?.profile?.phone,
				});

				if (window.Intercom) {
					window.Intercom('shutdown');
				}

				setToken(null);

				localStorage.removeItem(userIdKey);

				auth.stopSilentRenew();

				auth.clearStaleState();

				window?.analytics?.reset();

				let post_logout_redirect_uri = redirect;
				if (!post_logout_redirect_uri) {
					post_logout_redirect_uri = window.location.href;
				} else {
					post_logout_redirect_uri = redirect.includes('http')
						? redirect
						: window.location.origin + redirect;
				}

				auth.signoutRedirect({
					post_logout_redirect_uri,
					extraQueryParams: {
						kc_idp_hint: getIdpHint(auth.user.profile.providerId),
					},
				});

				isLoggingOut.current = false;
			});
	}

	return (
		<AuthContext.Provider
			value={{
				...auth,
				user: {
					...auth?.user,
					...auth?.user?.profile,
					trackingId: getUserId(),
					phone: auth?.user?.profile?.phone_number,
				},
				isLegal,
				login,
				logout,
				token,
				setToken,
				loading: loading || auth?.isLoading,
				method: _method,
			}}
			{...props}
		/>
	);
}

export function getEntry() {
	const pathSegments = window?.location?.pathname?.split('/').filter(Boolean); // Split and remove empty strings
	const firstSegment =
		pathSegments?.length > 0 ? pathSegments[0] : window?.location?.pathname;

	if (firstSegment?.includes('strom')) {
		return 'Strømbestilling';
	} else if (firstSegment?.includes('samtykke-portal')) {
		return 'Samtykke Portal';
	} else if (firstSegment?.includes('produkter')) {
		return 'Produkter';
	} else if (firstSegment?.includes('laget-mitt')) {
		return 'Laget Mitt';
	} else if (firstSegment?.includes('naermiljoet-mitt')) {
		return 'Nærmiljøet Mitt';
	} else {
		return 'Fiberbestilling';
	}
}

function getAge(dateString) {
	const birthDate = new Date(dateString);

	if (dateString === null || isNaN(birthDate)) return false;

	const today = new Date();
	let age = today.getFullYear() - birthDate.getFullYear();
	const m = today.getMonth() - birthDate.getMonth();
	if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
		age--;
	}

	return age;
}

export function useAuthMethodCheck(method) {
	const { logout, isAuthenticated, method: userMethod, loading } = useAuth();

	useEffect(() => {
		if (!isAuthenticated || !method || !userMethod || loading) return;

		if (userMethod !== method) {
			console.log('log out');
			logout();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthenticated, method, userMethod, loading]);
	return null;
}
